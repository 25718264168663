export const fallbackImage = {
  dimensions: {
    width: 6720,
    height: 4480,
  },
  alt: null,
  copyright: null,
  url: 'https://images.prismic.io/virginexperiencedays/6288bdd2-0da0-4db1-bc60-1403f1840a19_gray.jpeg?auto=compress,format',
  Post: {
    dimensions: {
      width: 1200,
      height: 480,
    },
    alt: null,
    copyright: null,
    url: 'https://images.prismic.io/virginexperiencedays/6288bdd2-0da0-4db1-bc60-1403f1840a19_gray.jpeg?auto=compress,format&rect=0,896,6720,2688&w=1200&h=480',
  },
  Thumbnail: {
    dimensions: {
      width: 400,
      height: 400,
    },
    alt: null,
    copyright: null,
    url: 'https://images.prismic.io/virginexperiencedays/6288bdd2-0da0-4db1-bc60-1403f1840a19_gray.jpeg?auto=compress,format&rect=1120,0,4480,4480&w=400&h=400',
  },
  OpenGraph: {
    dimensions: {
      width: 1200,
      height: 628,
    },
    alt: null,
    copyright: null,
    url: 'https://images.prismic.io/virginexperiencedays/6288bdd2-0da0-4db1-bc60-1403f1840a19_gray.jpeg?auto=compress,format&rect=0,482,6720,3517&w=1200&h=628',
  },
  Schema: {
    dimensions: {
      width: 460,
      height: 217,
    },
    alt: 'Virgin Experience Days Logo',
    copyright: null,
    url: 'https://images.prismic.io/virginexperiencedays/e0cf9e9f-3a51-4393-b984-e3a1cba09149_ved-schema-logo.png?auto=compress,format',
  },
};
