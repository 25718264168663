import Head from 'next/head';
import { fallbackImage } from '../../../utils/fallbackImage';
import { SEOType } from '@virginexperiencedays/cms';
import { homepageStructuredData } from './homepageStructuredData';
import { defaultMetaDescription, defaultMetaTitle } from '../../SEO/constants';

export type HomePageSEOType = Omit<
  SEOType,
  'og_type' | 'structuredData' | 'robots_controls' | 'canonical' | 'meta_robots'
> & { legacy_og_image?: string };

const BASE_URL = process?.env?.NEXT_PUBLIC_HOME_LINK ?? 'https://www.virginexperiencedays.co.uk/';

const enforceTrailingSlash = (url: string): string => {
  if (url.endsWith('/')) return url;
  return `${url}/`;
};

/* The existing SEO component doesn't meet the criteria outlined for the homepage metadata, 
   specifically the og fallbacks. Altering the SEO component risks SEO changes on many other pages */
const HomePageSEO = (seo: HomePageSEOType) => {
  if (!seo) return null;

  const image = seo?.og_image || fallbackImage.OpenGraph;
  const imageUrl = image?.url ?? seo?.legacy_og_image;

  const baseUrl = enforceTrailingSlash(BASE_URL);

  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta lang="en-gb" />
      <link rel="canonical" href={baseUrl} />
      <title>{seo?.meta_title}</title>
      <meta name="description" content={seo?.meta_description} />
      <meta property="og_site_name" content="Virgin Experience Days" />
      <meta property="og_locale" content="en_gb" />
      <meta property="og_url" content={baseUrl} />
      <meta property="og:title" content={seo?.og_title || seo.meta_title} />
      <meta property="og:description" content={seo?.og_description || seo.meta_title} />
      {imageUrl && (
        <>
          <meta property="og:image" content={imageUrl} />
          {image.dimensions.width && image.dimensions.height && (
            <>
              <meta property="og:image:width" content={String(image.dimensions.width)} />
              <meta property="og:image:height" content={String(image.dimensions.height)} />
            </>
          )}
        </>
      )}
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={defaultMetaTitle} />
      <meta name="twitter:description" content={defaultMetaDescription} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      <meta name="twitter:site" content="@virginexp" />
      <meta name="twitter:creator" content="@virginexp" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/favicon.ico" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      <script
        key="product-structured-data"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: homepageStructuredData({
            url: baseUrl,
            metaDescription: seo?.meta_description,
            image: image ?? fallbackImage.Schema,
          }),
        }}
      ></script>
    </Head>
  );
};

export default HomePageSEO;
