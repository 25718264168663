import { useRouter } from 'next/compat/router';
import { cn } from '@virginexperiencedays/tailwind';
import { SliceZone } from '@prismicio/react';

import { ByPassBlock } from '@virginexperiencedays/components-v2/src/ByPassBlock';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { RichTextWrap } from '@virginexperiencedays/components-v2/src/typography/RichTextWrap';
import { Stack } from '@virginexperiencedays/components-v2/src/layout/Stack';

import AccordionGroup from '@virginexperiencedays/cms/slicemachine/AccordionGroup';
import Button from '@virginexperiencedays/cms/slicemachine/Button';
import CarouselStructure from '@virginexperiencedays/cms/slicemachine/CarouselStructure';
import CarouselWithIntroSlot from '@virginexperiencedays/cms/slicemachine/CarouselWithIntroSlot';
import CarouselWithTabs from '@virginexperiencedays/cms/slicemachine/CarouselWithTabs';
import ChunkyMessage from '@virginexperiencedays/cms/slicemachine/ChunkyMessage';
import ColumnStructure from '@virginexperiencedays/cms/slicemachine/ColumnStructure';
import FaqAccordionGroup from '@virginexperiencedays/cms/slicemachine/FaqAccordionGroup';
import FeaturedProductGrid from '@virginexperiencedays/cms/slicemachine/FeaturedProductGrid';
import FeaturedVisualCards from '@virginexperiencedays/cms/slicemachine/FeaturedVisualCards';
import GridStructure from '@virginexperiencedays/cms/slicemachine/GridStructure';
import HeroStyle01 from '@virginexperiencedays/cms/slicemachine/HeroStyle01';
import HeroStyle02 from '@virginexperiencedays/cms/slicemachine/HeroStyle02';
import HeroStyle03 from '@virginexperiencedays/cms/slicemachine/HeroStyle03';
import HeroStyle04 from '@virginexperiencedays/cms/slicemachine/HeroStyle04';
import HeroStyle05 from '@virginexperiencedays/cms/slicemachine/HeroStyle05';
import HtmlEmbed from '@virginexperiencedays/cms/slicemachine/HtmlEmbed';
import IconBanners from '@virginexperiencedays/cms/slicemachine/IconBanners';
import ListOfLinks from '@virginexperiencedays/cms/slicemachine/ListOfLinks';
import MegaNuggets from '@virginexperiencedays/cms/slicemachine/MegaNuggets';
import Newsletter from '@virginexperiencedays/cms/slicemachine/Newsletter';
import Nugget from '@virginexperiencedays/cms/slicemachine/Nugget';
import PromoBanner from '@virginexperiencedays/cms/slicemachine/PromoBanner';
import RichText from '@virginexperiencedays/cms/slicemachine/RichText';
import Spacer from '@virginexperiencedays/cms/slicemachine/Spacer';

import DynamicYield from '../../DynamicYield/DynamicYield';
import HomePageSEO, { HomePageSEOType } from './HomePageSEO';
import { DyContextPageType } from '@virginexperiencedays/dy';
import { bind as dangerouslyBindClickTracking } from '../search/tracking/clicks';
import { getPageMetaData } from '../../SEO/utils/metadata';
import { useIsProductCardsV2 } from '../search/ProductCard';
import { slice as trackSlice } from '../search/tracking/interactions';
import { useHomePageTracking } from './tracking/useHomePageTracking';
import { PAGE_TYPES } from '../../tracking/gtm/consts';
import { submit as onNewsletterSubmit } from '../../../utils/newsletter';

import type { RichTextField, RTHeading1Node } from '@prismicio/types';

import type { BasePageProps as PageProps } from '../types';

const Page = ({ isDarkTheme, prismicPage, prismicSlices, slug, listOfLinks }: PageProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const router = useRouter();
  const pageTitle: RichTextField = prismicPage?.data?.page_title ?? null;
  const seo: HomePageSEOType = getPageMetaData(prismicPage?.data);

  const isSlicesExisting = !!prismicSlices?.length;
  const pageTitleObject =
    Array.isArray(pageTitle) && pageTitle.length && (pageTitle?.[0] as RTHeading1Node);
  const mainContentId = 'home-main-content';
  const pageType = PAGE_TYPES.Home;

  useHomePageTracking({ slices: prismicSlices });
  prismicSlices = dangerouslyBindClickTracking({
    slices: prismicSlices,
    slug: 'home',
    next: (url) => router.push(url),
    hasGAEvent: false,
    pageType,
    isProductCardsV2,
  });

  return (
    <>
      <HomePageSEO {...seo} />
      <DynamicYield type={DyContextPageType.HOMEPAGE} />
      <ByPassBlock mainContentId={mainContentId} />
      <Container
        id={mainContentId}
        className={cn(
          'flex flex-row flex-nowrap items-center justify-center py-4',
          '[&_h1]:m-0 [&_h1]:text-center [&_h1]:font-inter [&_h1]:text-sm [&_h1]:leading-[1.4] [&_h1]:text-neutral-strong'
        )}
      >
        <RichTextWrap field={pageTitle} />
      </Container>
      {isSlicesExisting && (
        <Stack>
          <SliceZone
            slices={prismicSlices}
            components={{
              accordion_group: AccordionGroup,
              button: Button,
              carousel_structure: CarouselStructure,
              carousel_with_intro_slot: CarouselWithIntroSlot,
              carousel_with_tabs: CarouselWithTabs,
              chunky_message: ChunkyMessage,
              column_structure: ColumnStructure,
              faq_accordion_group: FaqAccordionGroup,
              featured_product_grid: FeaturedProductGrid,
              featured_visual_cards: FeaturedVisualCards,
              grid_structure: GridStructure,
              hero_style01: HeroStyle01,
              hero_style02: HeroStyle02,
              hero_style03: HeroStyle03,
              hero_style04: HeroStyle04,
              hero_style05: HeroStyle05,
              html_embed: HtmlEmbed,
              icon_banners: IconBanners,
              list_of_links: ListOfLinks,
              mega_nuggets: MegaNuggets,
              newsletter: Newsletter,
              nugget: Nugget,
              promo_banner: PromoBanner,
              rich_text: RichText,
              spacer: Spacer,
            }}
            context={{
              router,
              slug: slug === '/' ? 'home' : slug,
              lastSlug: 'home',
              hide: false,
              pageTitle: pageTitleObject?.text ?? '',
              pageType,
              listOfLinks,
              isDarkTheme,
              isProductCardsV2,
              trackSlice,
              onNewsletterSubmit,
              overridesSlugInfo: null,
            }}
          />
        </Stack>
      )}
    </>
  );
};

export default Page;
