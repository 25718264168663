import type { PrismicImage } from '@virginexperiencedays/cms';
import { imageObject } from '../../SEO/utils/imageObject';

type Params = {
  url: string;
  image: PrismicImage;
  metaDescription?: string;
};

export const homepageStructuredData = ({ url, image, metaDescription }: Params) => {
  const schema = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        '@id': url,
        url,
        name: 'Virgin Experience Days',
        alternateName: 'Virgin Experience Days Ltd.',
        founder: [
          {
            '@type': 'Person',
            givenName: 'Richard',
            familyName: 'Branson',
          },
        ],
        parentOrganization: 'Equistone',
        sameAs: [
          'https://www.facebook.com/virginexperiencedays',
          'https://twitter.com/virginexp',
          'https://instagram.com/virginexperiencedays',
          'https://www.youtube.com/c/virginexperiencedays',
          'https://en.wikipedia.org/wiki/Virgin_Experience_Days',
        ],
        logo: imageObject({ image }),
        image: { '@id': image?.url || null },
      },
      {
        '@type': 'WebSite',
        name: 'Virgin Experience Days',
        '@id': url,
        url,
        inLanguage: 'en-GB',
        publisher: { '@id': url },
        potentialAction: {
          '@type': 'SearchAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: 'https://www.virginexperiencedays.co.uk/search?query={search_term_string}',
          },
          'query-input': 'required name=search_term_string',
        },
      },
      {
        '@type': 'WebPage',
        url,
        inLanguage: 'en-GB',
        name: 'Virgin Experience Days',
        description: metaDescription,
        isPartOf: { '@id': url },
        about: { '@id': url },
      },
    ],
  };

  return JSON.stringify(schema);
};
