import { getCookieMap } from '../../../../utils/cookie';
import { pageView as trackFbPageView } from '../../../tracking/fb/events';
import { GLOBAL_SETTINGS } from '../../../tracking/gtm/consts';
import { useTracking } from '../../../tracking/useTracking';
import { makeImpressions } from '../../search/tracking';

export const useHomePageTracking = ({ topProducts = [], slices = [] }) => {
  const impressions = makeImpressions({
    topProducts: Object.values(topProducts),
    slices,
    list: 'home',
  });

  useTracking({
    legacy: {
      pageType: 'Home',
    },
    gtm: {
      getInitial: ({ router }) => {
        const isAffinity =
          // check affinity cookie
          getCookieMap()?.has('affinity') ||
          // double check router.query as a fallback
          !!router?.query?.bid;

        return [
          {
            ...GLOBAL_SETTINGS,
            pageType: 'Home',
            ...(isAffinity && { environment: 'Affinity' }),
          },
          ...impressions.flatMap((impressions) => [
            { ecommerce: null }, // clear before every product slice impressions
            {
              event: 'ec:impressions',
              ecommerce: {
                currencyCode: 'GBP',
                impressions,
              },
            },
          ]),
        ];
      },
    },
    fb: () => {
      trackFbPageView({
        name: 'Home',
      });
    },
  });
};
